














































import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { config, retrievalNumber } from "@profile/config"

export default Vue.extend({
  name: "SupportModal",
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      retrNr: null as string|null,
      re: config.retrievalRegex,
      retTitle: retrievalNumber
    }
  },
  methods: {
    async continueInMarket() {
      this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        customer: {
          ...this.$store.state.project.customer,
          continueWithSalesman: false
        },
      });
      this.$store.commit("setUnsavedChanges", false);
      this.$bvModal.hide('bauhaus')
    },
    async sendMail() {
      if(this.$root.$refs.customer && ! await (this.$root.$refs.customer as any)?.validateForm()) return
      if(this.$refs.form && ! await (this.$refs.form as any).validate()) return
      if(this.$store.state.project.customer.firstName === "") throw new Error('Customer must not be empty')
      await this.$store.dispatch("updateProject",{
        ...this.$store.state.project,
        customer: {
          ...this.$store.state.project.customer,
          // continueWithSalesman: true
        },
        status: "planningByManufacturer",
        retrievalNumberPlanning: this.retrNr
      })
      this.$store.commit("setUnsavedChanges", false);
      await this.$store.dispatch("requestSupport")
      this.$router.push("/")
    }
  },
  created() {
    this.retrNr = this.$store.getters.getRetrievalNumber("planning")
  }
})
