






























































































import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import opt from "@/data/options";
import cloneDeep from "lodash/cloneDeep";
import * as profileConfig from "@profile/config"

export default Vue.extend({
  data() {
    return {
      payment: cloneDeep(this.$store.state.project.payment),
      customer: cloneDeep(this.$store.state.project.customer),
      promoCodes: [] as Array<any>,
      code: null,
      profileConfig
    };
  },
  components: { ValidationObserver, ValidatedFormField, ValidationProvider },
  computed: {
    isAdmin() {
      return this.$store.getters.userRole('admin')
    },
    paymentOptions() {
      return opt.paymentOptions
    },
    deliveryOptions() {
      return opt.deliveryOptionsBauhaus
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        payment: cloneDeep(this.payment),
        customer: cloneDeep(this.customer)
      });
      this.$store.commit("setUnsavedChanges", false);
    },
    async downloadPreview(type: string) {
      const pdf: Blob = await this.$store.dispatch("getPreview", type)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(pdf)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
  async created() {
    this.promoCodes = (await this.$store.dispatch("getPromoCodes")).filter((el: any) => el.active)
    this.$watch(()=>{
       if (this.payment.paymentOption == "zk3") {
        return (this.payment.discount = 2);
      } else if (this.payment.paymentOption == "zk2") {
        return (this.payment.discount = 1.5);
      } else if (this.payment.paymentOption == "zk1") {
        return (this.payment.discount = 0);
      } else {
        return 0;
      }
    },()=>{})
    this.$watch(
      () => this.payment,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
  },
  async mounted() {
    this.$root.$on('bv::modal::show', async (bvEvent: any, modalId: string) => {
      try {
        await this.save()
      } catch (e) {
        console.log(e)
      }
    })
  },
  async beforeRouteLeave(to, _from, next) {
    if (!["Projects", "CustomerInformation", "GeneralData", "Awning", "Lighting", "SidePanels", "Mounting"].includes(to.name ?? "") && !(await (this.$refs.form as any).validate()))
      return;
    try {
      await this.save()
    } catch (e) {
      console.log(e)
    }
    next();
  },
});
