






import Vue from "vue";
import { steps } from "@profile/config"
import store from "@/store";

export default Vue.extend({
  computed: {
   routes() {
      const rt = this.$router.options.routes!.find(r => r.name === 'Project')!.children!;
      return rt.filter((el: any) => { return steps(el.name) }).filter((step: any)=>{
        if(store.state.project.type === 'carport'){
          if(['Overview','Awning','SidePanels'].includes(step.name)){
            return false;
          }
        } else {
          if(['SolarCarport'].includes(step.name)){
            return false;
          }
        }
        return true;
      })
    },
    routeIndex(): number {
      return this.routes.findIndex(r => r.name === this.$route.name);
    },
    prev(): any {
      const route = this.routes[this.routeIndex - 1];
      return route && { name: route.name };
    },
    next(): any {
      const route = this.routes[this.routeIndex + 1];
      return route && { name: route.name };
    },
  },
});
