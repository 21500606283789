


























import Vue from "vue"
import router from "@/router"
import store from "@/store"
import { validateCustomer, validateConfiguration, validateAwning, validateSidePanels, validateLighting, validateMounting, ValidationError, validateCarport } from "@ldvsg/shared/lib/validation"
import PhoneSupport from "@/components/PhoneSupport.vue"
import * as profileConfig from "@profile/config"

interface Step {
  route: string
  name: string
  errors: ValidationError[]
}

export default Vue.extend({
  components: { PhoneSupport },
  data() {
    return {
      profileConfig
    }
  },
  name: "Sidebar",
  computed: {
    currentStep(): number {
      return this.steps.findIndex((step) => step.route == this.$route.name)
    },
    steps(): Step[] {
      const project = store.state.project!
      return [
        {
          route: "CustomerInformation",
          name: "Persönliche Daten",
          errors: validateCustomer(project.customer).errors
        },
        {
          route: "SolarCarport",
          name: "Solarcarport",
          errors: validateCarport(project.carport).errors,
          validType: ["carport"]
        },
        {
          route: "GeneralData",
          name: "Grundlegende Daten",
          errors: validateConfiguration(project.configuration).errors
        },
        {
          route: "Lighting",
          name: "Beleuchtung und Soundsystem",
          errors: validateLighting(project.lighting).errors
        },
        {
          route: "Overview",
          name: "Übersicht",
          errors: []
        },
        {
          route: "Awning",
          name: "Markise",
          errors: validateAwning(project.awning).errors
        },
        {
          route: "SidePanels",
          name: "Seitenelemente",
          errors: validateSidePanels(project.sidePanels).errors
        },
        {
          route: "Mounting",
          name: "Montage",
          errors: validateMounting(project.mounting).errors
        },
        {
          route: "Payment",
          name: "Bezahlung",
          errors: []
        },
        {
          route: "Summary",
          name: "Zusammenfassung",
          errors: []
        }
      ].filter(this.filterSteps).filter((step: any)=>{
        if(store.state.project.type === 'carport'){
          if(['Overview','Awning','SidePanels'].includes(step.route)){
            return false;
          }
        } else {
          if(['SolarCarport'].includes(step.route)){
            return false;
          }
        }
        return true;
      })
    }
  },
  methods: {
    deleteProject() {
      router.push("..").then(() => {
        store.dispatch("deleteProject", store.state.project!.id)
      })
    },
    filterSteps(step: any): boolean {
      return profileConfig.steps(step.route)
    },
    showModal() {
      this.$bvModal.show('bauhaus')
    }
  }
})
