var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v(_vm._s(_vm.profileConfig.awningTitle))]),_c('b-alert',{staticClass:"mt-3",attrs:{"dismissible":"","variant":"warning"}},[_vm._v("ab 3,51m Ausfall: Anlage mit fester Tuchstütze (im Lieferumfang enthalten)")]),_c('b-tabs',{staticClass:"mb-4",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.visibleTabs),function(type){return _c('b-tab',{key:type,attrs:{"title":type == 'roof' ? 'Markise Dach' : 'Markise Vorderseite',"lazy":""}},[_c('ValidationObserver',{ref:'form' + type,refInFor:true},[(type == 'roof')?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Variante"}},[_c('b-form-select',{attrs:{"options":_vm.variantOptions,"state":errors[0] ? false : valid ? undefined : null},on:{"change":function($event){return _vm.changeAwningEndIfVariantChanges()}},model:{value:(_vm.awning.variant),callback:function ($$v) {_vm.$set(_vm.awning, "variant", $$v)},expression:"awning.variant"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.profileConfig.modules.showFullFabricBrand)?_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Markisenhersteller"}},[_c('b-form-select',{attrs:{"options":_vm.fabricBrandOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awnings.fabricBrand),callback:function ($$v) {_vm.$set(_vm.awnings, "fabricBrand", $$v)},expression:"awnings.fabricBrand"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{ required: true, regex: _vm.fabricRegex }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Stoffnummer"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awnings.fabricID),callback:function ($$v) {_vm.$set(_vm.awnings, "fabricID", $$v)},expression:"awnings.fabricID"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Montage"}},[_c('b-form-radio-group',{attrs:{"options":_vm.booleanOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awning.mounting),callback:function ($$v) {_vm.$set(_vm.awning, "mounting", $$v)},expression:"awning.mounting"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Stoffnummer"}},[_c('b-form-select',{attrs:{"options":_vm.bauhausFabricOpt,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awnings.fabricID),callback:function ($$v) {_vm.$set(_vm.awnings, "fabricID", $$v)},expression:"awnings.fabricID"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('b-button',{staticClass:"no-disable",attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadFabric()}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"download"}}),_vm._v("Stoffauswahl ")],1)],1)],1),_c('Visualization',{staticClass:"my-4",attrs:{"awning":_vm.awning}}),_vm._l((_vm.awnings.awnings),function(item,index){return _c('b-card',{key:index,staticClass:"b-card mb-3",attrs:{"title":("Markise " + (index + 1))}},[_c('div',{staticClass:"d-flex align-items-end mb-3"},[_c('b-form-group',{staticClass:"mr-3 mb-0",staticStyle:{"flex-grow":"1"},attrs:{"label":_vm.activeTab == 0 ? 'Startsparren' : 'Pfosten'}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{on:{"click":function($event){return _vm.changeAwningStart(index, item.start - 1)}}},[_vm._v("-")])],1),_c('b-form-input',{staticClass:"text-center",attrs:{"value":_vm.activeTab == 0
                      ? item.start + 1
                      : ((item.start + 1) + " bis " + (item.start + 2)),"disabled":""}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.changeAwningStart(index, item.start + 1)}}},[_vm._v("+")])],1)],1)],1),(_vm.activeTab == 0)?_c('b-form-group',{staticClass:"mb-0 mr-3",staticStyle:{"flex-grow":"1"},attrs:{"label":"Endsparren"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{on:{"click":function($event){return _vm.changeAwningEnd(index, item.end - 1)}}},[_vm._v("-")])],1),_c('b-form-input',{staticClass:"text-center",attrs:{"value":item.end + 1,"disabled":""}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.changeAwningEnd(index, item.end + 1)}}},[_vm._v("+")])],1)],1)],1):_vm._e(),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeAwning(index)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1),_c('AwningOptions',{attrs:{"awning":item,"awningComplete":_vm.awning,"activeTab":_vm.activeTab}})],1)}),_c('div',{staticClass:"d-flex mb-4"},[(
              _vm.awnings.awnings.length == 0 ||
              _vm.awnings.awnings[_vm.awnings.awnings.length - 1].end < _vm.awningFields
            )?_c('b-button',{attrs:{"disabled":_vm.addAwningButton},on:{"click":function($event){return _vm.addAwning()}}},[_vm._v("Markise hinzufügen")]):_vm._e()],1)],2)],1)}),(_vm.profileConfig.modules.showSideAwning)?_c('b-tab',{attrs:{"title":"Markise Seite"}},[_c('ValidationObserver',{ref:"formside"},[_vm._l(([_vm.awning.awningLeft, _vm.awning.awningRight]),function(item,index){return [(item.awning != null)?_c('b-card',{key:index,staticClass:"my-4",attrs:{"title":((['Linke', 'Rechte'][index]) + " Seitenmarkise")}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Variante"}},[_c('b-form-select',{attrs:{"options":_vm.sideAwningVariantOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(item.sideAwningVariant),callback:function ($$v) {_vm.$set(item, "sideAwningVariant", $$v)},expression:"item.sideAwningVariant"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('AwningOptions',{attrs:{"awning":item.awning,"awningComplete":_vm.awning,"awningSide":item,"activeTab":_vm.activeTab}}),_c('b-button',{staticClass:"d-block mt-3",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeSideAwning(index)}}},[_vm._v(" "+_vm._s(["Linke", "Rechte"][index])+" Seitenmarkise entfernen ")])],1):_vm._e(),(item.awning == null && !_vm.postOffset(index))?_c('b-button',{key:index,staticClass:"d-block mt-3",on:{"click":function($event){return _vm.addSideAwning(index)}}},[_vm._v(_vm._s(["Linke", "Rechte"][index])+" Seitenmarkise hinzufügen")]):(_vm.postOffset(index))?_c('b-button',{key:index,staticClass:"d-block mt-3",attrs:{"disabled":""}},[_vm._v("Bei eingerückten Pfosten sind keine "+_vm._s(["linken", "rechten"][index])+" Seitenmarkisen möglich!")]):_vm._e()]})],2)],1):_vm._e()],2),_c('hr'),_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [(_vm.activeTab == 0)?_c('b-card',{attrs:{"title":"Markisenzubehör"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Wind-/Sonnenwächter"}},[_c('b-form-radio-group',{attrs:{"options":_vm.booleanOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awning.wsGuard),callback:function ($$v) {_vm.$set(_vm.awning, "wsGuard", $$v)},expression:"awning.wsGuard"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Fernbedienung"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.getTheRigthRemoteControl}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)],1)],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }