import { options as config } from "@profile/config"

export default {
  "referredOptions": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "ab_zum_angebot", "text": "Ab zum Angebot" },
    { "value": "aktion", "text": "Aktion" },
    { "value": "amazon", "text": "Amazon" },
    { "value": "ausstellung", "text": "Ausstellung" },
    { "value": "daa_net", "text": "DAA Net" },
    { "value": "email", "text": "E-Mail" },
    { "value": "ebay", "text": "Ebay" },
    { "value": "ebay_kleinanzeigen", "text": "Ebay-Kleinanzeigen" },
    { "value": "empfehlung", "text": "Empfehlung" },
    { "value": "facebook", "text": "Facebook" },
    { "value": "flyer", "text": "Flyer" },
    { "value": "konfigurator", "text": "Konfigurator" },
    { "value": "konfigurator_kampange", "text": "Konfigurator Kampange" },
    { "value": "messe", "text": "Messe" },
    { "value": "newsletter", "text": "Newsletter" },
    { "value": "telefon", "text": "Telefon" }
  ],
  "awningVariantOptions": [
    { "value": null, "text": "Bitte wählen", "disabled": false },
    { "value": "on_roof", "text": "Aufdach" },
    { "value": "under_glass", "text": "Unterglas" }
  ],
  "sideAwningVariantOptions":[
    { "value": null, "text": "Bitte wählen" ,"disabled": false },
    { "value": "pos1", "text": "Seitl. Markise auf den Pfosten mit Profilrahmen – keine Elemente gewünscht  (Pos. 1)" },
    { "value": "pos2", "text": "Seitl. Markise auf den Pfosten mit Dreieck – keine Elemente gewünscht (Pos. 2)" },
    { "value": "pos3", "text": "Seitl. Markise zwischen den Pfosten mit Profilrahmen – keine Schiebeanlage möglich (Pos. 3)" },
    { "value": "pos4", "text": "Seitl. Markisen zwischen den Pfosten mit Dreieck – keine Schiebeanlage möglich (Pos. 4)" },
    { "value": "pos5", "text": "Seitl. Markise auf den Pfosten mit Elementen – normale Konfiguration in Elementen vornehmen ( Pos. 5)" }

  ],
  "awningControls": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "motor", "text": "Standard-Steuerung (Schalter Bauseits)" },
    { "value": "motor_funk", "text": "Funkgesteuert (Vorgerüstet für Smart Home)" }
  ],
  "mountingPostOptions": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "on_posts", "text": "Auf den Pfosten" },
    { "value": "between_posts", "text": "Zwischen den Pfosten" }
  ],
  "fabricBrandOptions": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "lewens", "text": "Lewens" },
    { "value": "erhardt", "text": "Erhardt" }
  ],
  "awningEngineSide":[
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "left", "text": "Links" },
    { "value": "right", "text": "Rechts" }
  ],
  "lightingVariantOptions": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    ...config.additionalLightingOptions,
    { "value": "led_spots", "text": "Dimmbare LED Spots" },
  ],
  "lightingVariantOptionsAdditional": [
    { "value": null, "text": "Bitte wählen" },
    { "value": "led_spots", "text": "Dimmbare LED Spots" }
  ],
  carportLightingOptions:[
    {value: null, text: "Bitte wählen", disabled: true},
    {value: "solar_led_2", text: "2 LED Leuchten"},
    {value: "solar_led_4", text: "4 LED Leuchten"},
  ],
  "sidePanelVariants": [
    ...config.sidePanelVariants
  ],
  "panelOpt": [
    ...config.panelOpt
  ],
  panelOpeningOptions: [
    { value: "left", text: "Von Links nach Rechts", available: [2, 3, 4, 5, 6] },
    { value: "right", text: "Von Rechts nach Links", available: [2, 3, 4, 5, 6] },
    { value: "toWall", text: "vom Pfosten zur Hauswand", available: [0, 1] },
    { value: "toPost", text: "von der Hauswand zum Pfosten", available: [0, 1] },

  ],
  "boolOptions": [
    { "value": true, "text": "Ja" },
    { "value": false, "text": "Nein" }
  ],
  "color": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": 9016, "text": "Weiß 9016 Glatt" },
    { "value": 7016, "text": "Anthrazit 7016 Strukur" },
    { "value": 9007, "text": "Graualuminium 9007 Struktur" },
    ...config.additionalColorOptions
  ],
  carportColor: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: 9016, text: "Weiß 9016 Glatt" },
    { value: 7016, text: "Anthrazit 7016 Strukur" },
  ],
  "roofing": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "vsg_klar", "text": "VSG 10mm klar" },
    ...config.additionalRoofingOptions,
    { "value": "poly_klar", "text": "Poly klar" },
    { "value": "poly_opal", "text": "Poly Opal" },
    { "value": "poly_ir", "text": "Poly IR" }
  ],
  carportRoofing: [
    { value: "poly_opal", text: "Poly Opal" }
  ],
  solarOptions: [
    { value: "with_battery", text: "mit Batterie"},
    { value: "no_battery", text: "ohne Batterie"},
  ],
  "postVariant": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "eckig", "text": "Eckig" },
    { "value": "abgerundet", "text": "Abgerundet" }
  ],
  "wallOpt": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "massiv", "text": "Massiv" },
    { "value": "holzstaenderbauweise", "text": "Holzständerbauweise" }
  ],
  "mountingOpt": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "wand", "text":"Wand", "disabled": false },
    { "value": "aufdach", "text": "Aufdach", "disabled": false },
    { "value": "hausdachsparren", "text": "Hausdachsparren" },
    { "value": "freistehend", "text": "Freistehend" }
  ],
  "measuringOpt": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "links", "text": "Hausecke Links" },
    { "value": "rechts", "text": "Hausecke Rechts" },
    { "value": "laibung_links", "text": "Fensterlaibung Links" },
    { "value": "laibung_rechts", "text": "Fensterlaibung Rechts" }
  ],
  "postsOpt": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "fest", "text": "Fester Grund" },
    { "value": "lose", "text": "Loser Grund (Fundament benötigt)" }
  ],
  "paymentOptions": [
    ...config.paymentOptions
  ],
  "salesmanList": [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "v0031", "text": "V0030 | Matthias Tschirner" },
    { "value": "v0031", "text": "V0031 | Roland Günther" },
    { "value": "v0032", "text": "V0032 | Markus Stoth" },
    { "value": "v0033", "text": "V0033 | Marc Olbrück" },
    { "value": "v0038", "text": "V0038 | Kevin Einax" },
    { "value": "v0015", "text": "V0015 | Günter Weiszhar" },
    { "value": "v0034", "text": "V0034 | Marco Schönemann" },
    { "value": "v0036", "text": "V0036 | Sven Diettrich" },
    { "value": "v0037", "text": "V0037 | Ewald Lang" },
  ],
  "accessibilityOptions":[
    { "value": null, "text": "Bitte wählen"},
    { "value": "am", "text": "Vormittags 9.00-13.00 Uhr"},
    { "value": "pm", "text": "Nachmittags 13.00-18.00 Uhr"}
  ],
  "fabricOptionsBauhaus": [
    { "value": null, "text": "Bitte wählen", "disabled": true},
    { "value": "u137", "text": "U137"},
    { "value": "u140", "text": "U140"},
    { "value": "u335", "text": "U335"},
    { "value": "u406", "text": "U406"},
    { "value": "u409", "text": "U409"},
    { "value": "u411", "text": "U411"},
    { "value": "u415", "text": "U415"},
    { "value": "0867", "text": "0867"},
    { "value": "6688", "text": "6688"},
    { "value": "8396", "text": "8396"}
  ],
  "deliveryOptionsBauhaus": [
    { "value": null, "text": "Bitte wählen", "disabled": true},
    { "value": "bauhaus", "text": "Lieferung zum Fachzentrum"},
    { "value": "home", "text": "Endkundenbelieferung mit 4 Wege Stapler / Kran / je nach örtlicher Gegebenheit bis in den Garten Möglich (238€)"}
  ],
  "marketOptions": [
    { "value": "bauhaus", "text": "Bauhaus" },
    { "value": "baywa", "text": "Baywa" },
    { "value": "hellweg", "text": "Hellweg" }
  ],
  "statusOptions": [
    { id: "planning", title: "In Planung" },
    { id: "planningComplete", title: "Planung abgeschlossen" },
    { id: "orderPlaced", title: "Auftrag Erteilt" },
    { id: "denied", title: "Absage" },
    ...config.statusOptions,
    { id: "planningByManufacturer", title: "Planung durch Hersteller" },
    { id: "onSiteAppointment", title: "Termin vor Ort" },
    { id: "sentToConstructionMarket", title: "Auftrag an Baumarkt versendet" },
    { id: "inProgress", title: "In Bearbeitung" },
    { id: "delivery", title: "Auslieferung/Montage" },
    { id: "deleted", title: "Gelöscht" }
  ],
  typeOptions: [
    ...config.typeOptions
  ],
  carportFastning: [
    {value: 'freestand', text: "Freistehend"},
    {value: 'wallmount', text: "Wandbesfestigung"}
  ],
  carportEntry: [
    {value: 'front', text: "Vorne"},
    {value: 'side', text: "Seite"}
  ],
  carportSize: [
    {value: 'single', text: "Einzelcarport (ohne Unterzug)"},
    {value: 'single_smart', text: "Einzelcarport (Smart)"},
    {value: 'double', text: "Doppelcarport (ohne Mittelpfosten)"}
  ],
  carportVariants: [
    {value: "CPV001", size:"single", text: "Einzelcarport Freistehend", width: 3380, depth: 5750, heightWall: 2516, heightGutter: 2000},
    {value: "CPV002", size:"single", text: "Einzelcarport Wand Kopf", wall: 'head', width: 3380, depth: 5750, heightWall: 2516, heightGutter: 2000},
    {value: "CPV003", size:"single", text: "Einzelcarport Wand Seite", wall: 'side', width: 5750, depth: 3380, heightWall: 2309, heightGutter: 2000},
    {value: "CPV004", size:"double", text: "Doppelcarport Freistehend", width: 5850, depth: 5750, heightWall: 2516, heightGutter: 2000},
    {value: "CPV005", size:"double", text: "Doppelcarport Wand Kopf", wall: 'head', width: 5850, depth: 5750, heightWall: 2516, heightGutter: 2000},
    // {value: "CPV006", size:"double", text: "Doppelcarport Wand Seite", wall: 'side', width: 5850, depth: 5750, heightWall: 2516, heightGutter: 2000},
    {value: "CPV007", size:"double", text: "Doppelcarport Wand Seite Lang", wall: 'side', width: 8600, depth: 3380, heightWall: 2309, heightGutter: 2000},
    {value: "CPV008", size:"single_smart", text: "Einzelcarport Freistehend (smart)", width: 2800, depth: 5500, heightWall: 2494, heightGutter: 2000},
    {value: "CPV009", size:"single_smart", text: "Einzelcarport Wand Kopf (smart)", wall: 'head', width: 2800, depth: 5500, heightWall: 2494, heightGutter: 2000},
    {value: "CPV010", size:"single_smart", text: "Einzelcarport Wand Seite (smart)", wall: 'side', width: 5500, depth: 2800, heightWall: 2296, heightGutter: 2000},
  ],
}
