var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v("Solarcarport")]),_c('div',{staticClass:"d-flex justify-content-center position-relative"},[_c('CarportPreview',{attrs:{"deg":_vm.carport.carportOrientation,"free":_vm.carport.carportFastning === 'freestand',"front":_vm.carport.carportEntry === 'front',"pos":_vm.carport.carportPosition,"single":_vm.carport.carportSize !== 'double',"smart":_vm.carport.carportSize === 'single_smart',"long":_vm.carport.carportVariantId === 'CPV007'},model:{value:(_vm.carport.carportPosition),callback:function ($$v) {_vm.$set(_vm.carport, "carportPosition", $$v)},expression:"carport.carportPosition"}}),_c('div',{staticClass:"sizes"},[_c('strong',[_vm._v("Maße")]),_c('p',{staticClass:"mb-0"},[_vm._v("Breite: "+_vm._s(_vm.variant.width / 1000)+"m")]),_c('p',{staticClass:"mb-0"},[_vm._v("Tiefe: "+_vm._s(_vm.variant.depth / 1000)+"m")])])],1),_vm._m(0),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.save)}}},[_c('b-row',{staticClass:"justify-content-between"},[_c('b-button',{staticClass:"no-disable",attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadPreview('beschattung')}}},[_vm._v(" Checkliste Beschattung ")]),_c('b-button',{attrs:{"variant":"link","href":_vm.mapsLink,"target":"_blank"}},[_vm._v(" Ausrichtung prüfen ")])],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ausrichtung"}},[_c('b-form-input',{attrs:{"type":'range',"min":0,"max":360,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.carport.carportOrientation),callback:function ($$v) {_vm.$set(_vm.carport, "carportOrientation", _vm._n($$v))},expression:"carport.carportOrientation"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('span',[_vm._v(_vm._s(_vm.carport.carportOrientation)+"°")])],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Befestigung"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.options.carportFastning,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.carport.carportFastning),callback:function ($$v) {_vm.$set(_vm.carport, "carportFastning", $$v)},expression:"carport.carportFastning"}})],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Einfahrtrichtung"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.options.carportEntry,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.carport.carportEntry),callback:function ($$v) {_vm.$set(_vm.carport, "carportEntry", $$v)},expression:"carport.carportEntry"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Die Einfahrtrichtung entspricht der Orientierung des Autos im Schema.","variant":"link"}},[_c('b-icon-question-circle-fill',{attrs:{"font-scale":"1.5","variant":"secondary"}})],1)],1)],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Einzel- oder Doppelcarport"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.options.carportSize,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.carport.carportSize),callback:function ($$v) {_vm.$set(_vm.carport, "carportSize", $$v)},expression:"carport.carportSize"}})],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Variante"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.variants,"state":errors[0] ? false : valid ? undefined : null,"disabled":_vm.variants.length < 2},model:{value:(_vm.carport.carportVariantId),callback:function ($$v) {_vm.$set(_vm.carport, "carportVariantId", $$v)},expression:"carport.carportVariantId"}})],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Solarvariante"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.sOptions != null ? _vm.sOptions : _vm.options.solarOptions,"state":errors[0] ? false : valid ? undefined : null,"disabled":_vm.carport.carportSize === 'single_smart'},model:{value:(_vm.carport.solarVariant),callback:function ($$v) {_vm.$set(_vm.carport, "solarVariant", $$v)},expression:"carport.solarVariant"}})],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('strong',[_c('p',[_vm._v("Achten Sie auf die Sonneneinstrahlung!")])])}]

export { render, staticRenderFns }