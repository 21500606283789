


















































































































import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import opt from "@/data/options";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import { ValidationObserver } from "vee-validate";
import * as profileConfig from "@profile/config"


const postalCodesPromise = import("@/data/postal-codes.json").then(({ default: postalCodeData }) => {
  return new Map(Array(postalCodeData[2].length / 3).fill(null).map((_, i) => {
    const stateI = postalCodeData[2][i * 3 + 1]
    const placeI = postalCodeData[2][i * 3 + 2]
    return [postalCodeData[2][i * 3], {
      state: typeof stateI == "number" ? postalCodeData[0][stateI] as string : stateI,
      place: typeof placeI == "number" ? postalCodeData[1][placeI] as string : placeI
    }]
  }))
})

let postalCodes: Map<string, any>

postalCodesPromise.then(map => postalCodes = map as any)

export default Vue.extend({
  data() {
    return {
      postalCodesLoaded: false,
      customer: cloneDeep(this.$store.state.project.customer),
      houseNumberRegex: /^[0-9]+\w?$/,
      profileConfig
    };
  },
  components: {
    ValidatedFormField,
    ValidationObserver,
  },
  methods: {
    async validateForm() {
      return await (this.$refs.form as any).validate()
    },
    async save() {
      this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        customer: {
          ...cloneDeep(this.customer),
          continueWithSalesman: this.$store.state.project.customer.continueWithSalesman
        },
      });
      this.$store.commit("setUnsavedChanges", false);
    },
    
  },
  computed: {
    accessibilityOptions: function () {
      return opt.accessibilityOptions;
    },
    referredOptions: function () {
      return opt.referredOptions;
    },
    boolOptions: function () {
      return opt.boolOptions;
    },
    salesmanList: function () {
      return opt.salesmanList;
    },
    federalState(): string | undefined {
      if (!this.postalCodesLoaded) return
      const info = postalCodes.get(this.customer.address.postalCode)
      return info && info.state
    }
  },
  created() {
    postalCodesPromise.then(() => this.postalCodesLoaded = true)
    this.$watch(() => (this.postalCodesLoaded, this.customer.address.postalCode), postalCode => {
      if (!this.postalCodesLoaded) return
      const info = postalCodes.get(postalCode)
      if (info) {
        this.customer.address.city = info.place
        this.customer.address.federalState = info.state
      }
    })
    this.$watch(
      () => this.customer,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
    this.$root.$refs.customer = this
  },
  async beforeRouteLeave(to, _from, next) {
    if (!(await this.validateForm()) && to.matched[0].name == "Project")
      return;
    try {
      await this.save()
    } catch (e) {
      console.log(e)
    }
    next()
  },
  async mounted() {
    this.$root.$on('bv::modal::show', async (bvEvent: any, modalId: string) => {
      try {
        await this.save()
      } catch (e) {
        console.log(e)
      }
    })
  }
});
