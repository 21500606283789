











































































































import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep';
import store from "@/store";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import CarportPreview from "@/components/CarportPreview.vue";
import options from "@/data/options";
import { Carport, Project } from "@ldvsg/shared";

export default Vue.extend({
  components: { ValidationObserver, ValidatedFormField, ValidationProvider, CarportPreview },
  data() {
    return {
      carport: cloneDeep(store.state.project.configuration.carport as Carport || {
        carportOrientation: 0,
        carportPosition: 1,
        carportEntry: "front",
        carportVariantId: "CPV001",
        carportFastning: "freestand",
        carportSize: "single",
        solarVariant: "with_battery",
      } as Carport),
      singleOptions: [{value: "with_battery", text: "mit Batteriespeicher (5 KW)"}, { value: "no_battery", text: "ohne Batterie"}],
      doubleOptions: [{value: "with_battery", text: "mit Batteriespeicher (5 KW)"}, { value: "no_battery", text: "ohne Batterie"}]
    }
  },
  methods: {
    async save() {
      console.log('save');
      
      this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        configuration: {
          ...this.$store.state.project.configuration,
          width: this.variant.width,
          depth: this.variant.depth,
          heightWall: this.variant.heightWall,
          heightGutter: this.variant.heightGutter,
          roofingVariant: 'poly_opal',
          carport: this.carport,
        }
      }as Project);
      this.$store.commit("setUnsavedChanges", false);
    },
    async downloadPreview(type: string) {
      const pdf: Blob = await this.$store.dispatch("getPreview", type)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(pdf)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
  computed: {
    options(): typeof options {
      return options;
    },
    variants(): typeof options.carportVariants {

      const entryFront = this.carport.carportEntry === 'front';

      const wallHead = entryFront ? Boolean(this.carport.carportPosition! % 2) : !Boolean(this.carport.carportPosition! % 2);
      const wallSide = this.carport.carportFastning === 'freestand' ? undefined : (wallHead ? 'head' : 'side');

      return this.options.carportVariants.filter(v => {

        if (v.size !== this.carport.carportSize) {
          return false;
        }

        if(v.wall !== wallSide){
          return false;
        }

        return true
      });
    },
    variant(): typeof options.carportVariants[0] {
      return options.carportVariants.find(variant => variant.value === this.carport.carportVariantId)!;
    },
    mapsLink(): string{
      const address = this.$store.state.project.customer.address
      return `https://www.google.com/maps/place/${address.street}+${address.houseNumber}+${address.postalCode}+${address.city}`
    },
    sOptions(): {value:string, text:string}[] | null{
      if(this.carport.carportSize === "single"){
        return this.singleOptions
      }
      if(this.carport.carportSize === "double") {
        return this.doubleOptions
      }
      return null
    }
  },
  watch: {
    carport: {
      deep: true,
      handler() {
        console.log('Carport changed');

        if( !this.variants.map(v => v.value).includes(this.carport.carportVariantId!) ){
          this.carport.carportVariantId = this.variants[0].value;
        }

        if(this.carport.carportSize === 'single_smart'){
          this.carport.solarVariant = this.options.solarOptions[1].value
        }

        this.$store.commit("setUnsavedChanges", true);
      }
    }
  },
  async beforeRouteLeave(to, _from, next) {
    if (!["Projects", "CustomerInformation"].includes(to.name ?? "") && !(await (this.$refs.form as any).validate()))
      return;
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },
})
