





















import Vue from 'vue'
import FAQModal from "@/components/FAQModal.vue"

export default Vue.extend({
  components: { FAQModal },
  methods: {
    showFAQModal() {
      this.$bvModal.show('faq')
    }
  }
})
