




















































































































































import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import cloneDeep from "lodash/cloneDeep";
import opt from "@/data/options";
import { logic } from "@ldvsg/shared";
import store from "@/store";
import { profile } from "@profile/config"

export default Vue.extend({
  data() {
    return {
      sidePanels: cloneDeep(this.$store.state.project.sidePanels),
      activeTab: 0,
      profile,
    };
  },
  components: { ValidationObserver, ValidatedFormField, ValidationProvider },
  computed: {
    width: () => store.state.project?.configuration.width,
    depth: () => store.state.project?.configuration.depth,
    heightGutter: () => store.state.project?.configuration?.heightGutter,
    totalPosts: () => store.state.project?.configuration?.postCount,
    triangleHeight: () => store.getters.triangleHeight,
    postOffsets: () => store.getters.postOffsets,
    options: () => opt.boolOptions,
    sideOptions(): any[] {
      return opt.panelOpeningOptions.filter(it => {
        return it.available.includes(this.activeTab) || it.available[0] == 2 && this.activeTab >= 2
      })
    },
    panelOpt: () => opt.panelOpt,
    awnings: () => [
      store.state.project?.awning.awningLeft.awning,
      store.state.project?.awning.awningRight.awning
    ],
    leftSideAwningVariant: () => store.state.project?.awning.awningLeft.sideAwningVariant,
    rightSideAwningVariant: () => store.state.project?.awning.awningRight.sideAwningVariant,

    variants(): any[] {
      return opt.sidePanelVariants.filter(it => this.activeTab <= 1 || it.value !== "dreieck").map(it => ({...it, text: this.activeTab <= 1 && it.text_seite || it.text}))
    }
  },
  methods: {
    disable(name: string[]) {
      name.forEach(entry => {
        this.variants.find((opt) => opt.value == entry).disabled = true;
      })
    },
    enable(name: string[]) {
      name.forEach(entry => {
        this.variants.find((opt) => opt.value == entry).disabled = false;
      })
    },
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        sidePanels: cloneDeep(this.sidePanels)
      })
      this.$store.commit("setUnsavedChanges", false)
    },
    sidePossibleOffset(idx: number): boolean {
      if (idx == 0 && this.postOffsets[0] != 0) return false
      if (idx == 1 && this.postOffsets[this.postOffsets.length - 1] != 0) return false
      return true
    },
    sidePossiblePostMounting(idx: number): boolean {
      return store.state.project?.awning.mountingPostPosition == "between_posts"
        ? this.awnings[idx] == null
        : true
    },
    async downloadPreview(type: string) {
      const pdf: Blob = await this.$store.dispatch("getPreview", type)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(pdf)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
  created() {
    this.$watch(
      () => this.sidePanels,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
    if (this.sidePanels.sidePanels === null) {
      this.sidePanels.sidePanels = [
        {
          side: "Links",
          panelOpt: null,
          variant: null,
          width: this.depth,
          accessories: null,
          plankHeight: null,
          opening: null,
        },
        {
          side: "Rechts",
          panelOpt: null,
          variant: null,
          width: this.depth,
          accessories: null,
          plankHeight: null,
          opening: null,
        },
      ];
      const sides = logic.sides(this.totalPosts!);
      for (var i = 0; i < sides; i++) {
        this.sidePanels.sidePanels.push({
          side: "Vorne " + (i + 1),
          panelOpt: null,
          variant: null,
          width: logic.offsetPanelWidth(
            i,
            this.postOffsets,
            this.width!,
            this.totalPosts!
          ),
          accessories: null,
          plankHeight: null,
          opening: null,
        });
      }
    }
    const sides = logic.sides(this.totalPosts!);

    while (this.sidePanels.sidePanels.length - 2 > sides) {
      this.sidePanels.sidePanels.pop();
    }
    while (this.sidePanels.sidePanels.length - 2 < sides) {
      this.sidePanels.sidePanels.push({
        side: "Vorne " + (this.sidePanels.sidePanels.length - 1),
        panelOpt: null,
        variant: null,
        width: logic.panelWidth(this.width!, this.totalPosts!),
        accessories: null,
        plankHeight: null,
        opening: null,
      });
    }
    for (var i = 0; i < sides; i++) {
      this.sidePanels.sidePanels[i + 2].width = logic.offsetPanelWidth(
        i,
        this.postOffsets,
        this.width!,
        this.totalPosts!
      );
    }
    this.$watch(
      () => {
        if (this.leftSideAwningVariant && this.activeTab == 0) {
          switch (this.leftSideAwningVariant) {
            case "pos1":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
                "dreieck",
              ]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos2":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
              ]);
              this.enable(["dreieck"]);
              this.sidePanels.sidePanels[0].variant = "dreieck";
              break;
            case "pos3":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
              ]);
              this.enable(["festelement", "dreieck"]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos4":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
              ]);
              this.enable(["festelement", "dreieck"]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos5":
              this.enable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
                "dreieck",
              ]);
              break;
          }
        } else if (this.activeTab == 0)
          this.enable([
            "ganzglas_schiebeanlage",
            "gerahmte_schiebeanlage",
            "festelement",
            "dreieck",
          ]);

        if (this.rightSideAwningVariant && this.activeTab == 1) {
          switch (this.rightSideAwningVariant) {
            case "pos1":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
                "dreieck",
              ]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos2":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
              ]);
              this.sidePanels.sidePanels[1].variant = "dreieck";
              break;
            case "pos3":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
              ]);
              this.enable(["festelement", "dreieck"]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos4":
              this.disable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
              ]);
              this.enable(["festelement", "dreieck"]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos5":
              this.enable([
                "ganzglas_schiebeanlage",
                "gerahmte_schiebeanlage",
                "festelement",
                "dreieck",
              ]);
              break;
          }
        } else if (this.activeTab == 1)
          this.enable([
            "ganzglas_schiebeanlage",
            "gerahmte_schiebeanlage",
            "festelement",
            "dreieck",
          ]);
        if (this.activeTab > 1)
          this.enable([
            "ganzglas_schiebeanlage",
            "gerahmte_schiebeanlage",
            "festelement",
          ]);
      },
      () => {}
    );
  },
  async beforeRouteLeave(to, _from, next) {
    if (
      ![
        "Projects",
        "CustomerInformation",
        "GeneralData",
        "Awning",
        "Lighting",
      ].includes(to.name ?? "") &&
      !(await (this.$refs.form as any).validate())
    )
      return;
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },
});
