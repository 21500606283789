var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v("Seitenelemente (Ansicht von Außen)")]),(_vm.heightGutter > 2150)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.submit)}}},[_c('b-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.sidePanels.sidePanels),function(item,index){return _c('b-tab',{key:item.side,attrs:{"title":item.side}},[(_vm.sidePossibleOffset(index) && _vm.sidePossiblePostMounting(index))?[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Variante"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.variants,"state":errors[0] ? false : valid ? undefined : null},model:{value:(item.variant),callback:function ($$v) {_vm.$set(item, "variant", $$v)},expression:"item.variant"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"no-disable",attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadPreview('sidepanel')}}},[_vm._v(" Beispiel Variante ")])],1)],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(item.variant === 'festelement')?_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Art"}},[_c('b-form-select',{attrs:{"options":_vm.panelOpt,"state":errors[0] ? false : valid ? undefined : null},model:{value:(item.panelOpt),callback:function ($$v) {_vm.$set(item, "panelOpt", $$v)},expression:"item.panelOpt"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(item.panelOpt === 'aluplanken')?_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Höhe in mm"}},[_c('b-form-input',{attrs:{"type":"number","step":"150","state":errors[0] ? false : valid ? undefined : null},model:{value:(item.plankHeight),callback:function ($$v) {_vm.$set(item, "plankHeight", _vm._n($$v))},expression:"item.plankHeight"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('ValidatedFormField',{attrs:{"name":"Breite","rules":"","type":"number","disabled":""},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}}),_c('ValidatedFormField',{attrs:{"name":"Seite","rules":"required","disabled":""},model:{value:(item.side),callback:function ($$v) {_vm.$set(item, "side", $$v)},expression:"item.side"}}),(['ganzglas_schiebeanlage', 'gerahmte_schiebeanlage'].includes(item.variant))?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Öffungsrichtung"}},[_c('b-form-radio-group',{attrs:{"options":_vm.sideOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(item.opening),callback:function ($$v) {_vm.$set(item, "opening", $$v)},expression:"item.opening"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Zubehör"}},[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? undefined : null},model:{value:(item.accessories),callback:function ($$v) {_vm.$set(item, "accessories", $$v)},expression:"item.accessories"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){item.variant = ''}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)]:(!_vm.sidePossibleOffset(index))?[_c('h3',{staticClass:"mt-3"},[_vm._v(" An dieser Seite sind keine Elemente möglich, da der Pfosten eingerückt ist! ")])]:(!_vm.sidePossiblePostMounting(index))?[_c('h3',{staticClass:"mt-3"},[_vm._v(" An dieser Seite sind keine Elemente möglich, da die Markise zwischen den Pfosten montiert ist! ")])]:_vm._e()],2)}),1),(_vm.profile === 'ldvsg')?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Montage"}},[_c('b-form-radio-group',{attrs:{"options":_vm.options,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.sidePanels.mounting),callback:function ($$v) {_vm.$set(_vm.sidePanels, "mounting", $$v)},expression:"sidePanels.mounting"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e()],1)]}}],null,false,400433984)})]:[_c('h2',[_vm._v(" Keine Seitenelemente möglich, da Unterkante Rinne kleiner als 2150mm ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }