var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v("Montage")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.save)}}},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wandaufbau"}},[_c('b-form-select',{attrs:{"options":_vm.wallOpt,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.mounting.wall),callback:function ($$v) {_vm.$set(_vm.mounting, "wall", $$v)},expression:"mounting.wall"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Dämmungsstärke","rules":"required","type":"number"},model:{value:(_vm.mounting.wallThickness),callback:function ($$v) {_vm.$set(_vm.mounting, "wallThickness", _vm._n($$v))},expression:"mounting.wallThickness"}})],1),_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Steinart","rules":"required","type":"text"},model:{value:(_vm.mounting.wallMaterial),callback:function ($$v) {_vm.$set(_vm.mounting, "wallMaterial", $$v)},expression:"mounting.wallMaterial"}})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Montagevariante"}},[_c('b-form-select',{attrs:{"options":_vm.mountingOpt,"state":errors[0] ? false : valid ? undefined : null,"disabled":_vm.toThick},model:{value:(_vm.mounting.mountingVariant),callback:function ($$v) {_vm.$set(_vm.mounting, "mountingVariant", $$v)},expression:"mounting.mountingVariant"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.mounting.mountingVariant === 'freistehend')?[_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Anzahl der hinteren Pfosten","rules":"required","type":"number"},model:{value:(_vm.mounting.rearPosts),callback:function ($$v) {_vm.$set(_vm.mounting, "rearPosts", _vm._n($$v))},expression:"mounting.rearPosts"}})],1),_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Länge der hinteren Pfosten in mm","rules":"","value":_vm.mounting.rearPostsLength,"type":"number","disabled":""}})],1)]:_vm._e(),(['aufdach', 'hausdachsparren'].includes(_vm.mounting.mountingVariant))?_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Anzahl Konsolen","rules":"required","value":_vm.mounting.consoleAmount,"type":"number","disabled":""}})],1):_vm._e()],2),_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Einmesspunkt"}},[_c('b-form-select',{attrs:{"options":_vm.measuringOpt,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.mounting.measuring),callback:function ($$v) {_vm.$set(_vm.mounting, "measuring", $$v)},expression:"mounting.measuring"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidatedFormField',{attrs:{"name":"Gemessener Wert in mm","rules":"required","type":"number"},model:{value:(_vm.mounting.measurement),callback:function ($$v) {_vm.$set(_vm.mounting, "measurement", _vm._n($$v))},expression:"mounting.measurement"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Thermodübel"}},[_c('b-form-radio-group',{attrs:{"options":_vm.booleanOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.mounting.thermoDowels),callback:function ($$v) {_vm.$set(_vm.mounting, "thermoDowels", $$v)},expression:"mounting.thermoDowels"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fallrohrdurchführung"}},[_c('b-form-radio-group',{attrs:{"options":_vm.booleanOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.mounting.downpipeDuct),callback:function ($$v) {_vm.$set(_vm.mounting, "downpipeDuct", $$v)},expression:"mounting.downpipeDuct"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),(_vm.mounting.mountingVariant === 'freistehend')?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Einbetonierte Pfosten"}},[_c('b-form-radio-group',{attrs:{"options":_vm.foundationOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.mounting.foundationSide),callback:function ($$v) {_vm.$set(_vm.mounting, "foundationSide", $$v)},expression:"mounting.foundationSide"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]:_vm._e(),_vm._l((_vm.postArray),function(postMounting,i){return [_c('h3',{key:i},[_vm._v(_vm._s(['Vordere', 'Hintere'][i])+" Pfosten")]),_vm._l((postMounting),function(item,index){return _c('b-row',{key:("item-" + index + "-" + i)},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Pfostenmontage " + ((index+1)))}},[_c('b-form-select',{attrs:{"options":_vm.postsOpt,"state":errors[0] ? false : valid ? undefined : null,"disabled":i == _vm.foundationDisabled},model:{value:(item.foundation),callback:function ($$v) {_vm.$set(item, "foundation", $$v)},expression:"item.foundation"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Pflasteranpassung " + ((index+1)))}},[_c('b-form-checkbox',{attrs:{"state":errors[0] ? false : valid ? undefined : null},model:{value:(item.groundAdjustment),callback:function ($$v) {_vm.$set(item, "groundAdjustment", $$v)},expression:"item.groundAdjustment"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)})]})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }