





















































































import Vue, { PropType } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import options from "@/data/options";
import { mapGetters } from "vuex";
import store from "@/store";
import { logic } from "@ldvsg/shared";

export default Vue.extend({
  components: {
    ValidationProvider,
  },
  data() {
    return {};
  },
  props: {
    awning: { type: Object, required: true },
    awningComplete: { type: Object, required: true },
    awningSide: { type: Object, required: false },
    activeTab: { type: Number, required: true },
  },
  computed: {
    width: () => store.state.project?.configuration.width,
    depth: () => store.state.project?.configuration.depth,
    postVariant: () => store.state.project?.configuration.postVariant,
    totalPosts: () => store.state.project?.configuration.postCount,
    ...mapGetters(["fieldWidth", "roofLength", "heightGutter", "postOffsets"]),
    awningSize() {
      const { width, depth } = logic.awningSize(
        this.activeTab,
        this.awning.start,
        this.awning.end,
        this.fieldWidth,
        this.width,
        this.totalPosts,
        this.postOffsets,
        this.awningComplete.variant,
        this.depth,
        this.heightGutter
      );
      return `${width} x ${depth}`;
    },
    controlOptions() {
      return options.awningControls;
    },
    mountingPostPosition() {
      return options.mountingPostOptions;
    },
    awningEngineSideOptions() {
      return options.awningEngineSide;
    },
  },
});
