var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v("Zahlungsart")]),_c('ValidationObserver',{ref:"form"},[_c('b-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Zahlungsart"}},[_c('b-form-select',{attrs:{"options":_vm.paymentOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.payment.paymentOption),callback:function ($$v) {_vm.$set(_vm.payment, "paymentOption", $$v)},expression:"payment.paymentOption"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(_vm.profileConfig.modules.showDeliveryPreferences)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lieferung"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.deliveryOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.customer.deliveryPreferences),callback:function ($$v) {_vm.$set(_vm.customer, "deliveryPreferences", $$v)},expression:"customer.deliveryPreferences"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"no-disable",attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadPreview('packaging')}}},[_vm._v(" Beispiel Transportbox ")])],1)],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2080383290)}):_vm._e(),(_vm.profileConfig.modules.showPromoCodes && _vm.promoCodes.length > 0)?_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aktionscode"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.payment.promoCode),callback:function ($$v) {_vm.$set(_vm.payment, "promoCode", $$v)},expression:"payment.promoCode"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Bitte wählen")]),_vm._l((_vm.promoCodes),function(code){return _c('b-form-select-option',{key:code.value,attrs:{"value":code}},[_vm._v(" ["+_vm._s(code.value)+"] "+_vm._s(code.text)+" ")])})],2),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1901438279)}):_vm._e(),(_vm.profileConfig.modules.showDiscount)?_c('ValidatedFormField',{attrs:{"name":"Rabatt in Prozent","rules":"","type":"number","value":_vm.payment.discount,"disabled":""}}):_vm._e(),(_vm.isAdmin)?_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sonderabmachung"}},[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.payment.extra),callback:function ($$v) {_vm.$set(_vm.payment, "extra", $$v)},expression:"payment.extra"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1950079749)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }