

















import Vue from "vue";

export default Vue.extend({
  props: {
    deg: {
      type: Number,
      required: true,
    },
    free: {
      type: Boolean,
      required: true,
    },
    front: {
      type: Boolean,
      required: true,
    },
    single: {
      type: Boolean,
      required: true,
    },
    smart: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Number,
      required: false,
    }
  },
  data(){
    return {
      pos: this.value,
      debug: {
        deg: 0,
        delta: 0,
        factor: 0,
        yFactor: 0,
      }
    }
  },
  watch: {
    value(){
      this.pos = this.value || 0;
    },
    pos(){
      this.$emit('input', this.pos);
    },
  },
  computed: {
    rad(): number {
      return (this.deg * Math.PI) / 180;
    },
    shadowPath(): string {
      const maxDelta = (Math.sqrt(20000) - 100);
      const maxYDelta = 200;
      const factor = Math.abs(Math.sin(this.rad * 2));
      const yFactor = (this.deg % 90 / 90);
      const delta = factor * maxDelta;
      const yDelta = yFactor * maxYDelta;

      this.debug = {
        deg: this.deg,
        delta,
        factor,
        yFactor,
      }

      const p1 = {
        x: 150 - delta,
        y: 0,
      }
      const p2 = {
        x: 350 + delta,
        y: 0,
      }
      const p3 = {
        x: 350 + delta,
        y: 150 + yDelta,
      }
      const p4 = {
        x: 150 - delta,
        y: 350 - yDelta,
      }

      return `polygon(${p1.x}px ${p1.y}px, ${p2.x}px ${p2.y}px, ${p3.x}px ${p3.y}px, ${p4.x}px ${p4.y}px)`
    }
  }
});
