import { keycloak } from "@/auth"
import { Bom, Project } from "@ldvsg/shared"
import { ProjectFilter } from "./types"
import { authConfig } from "@profile/config"

export function mapProject(data: any): Project {
  return {
    ...data,
    createdAt: new Date(data.createdAt),
    lastUpdated: new Date(data.lastUpdated)
  }
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit, throwOnError = true) {
  const response = await fetch(`${input}`, {
    ...init,
    headers: {
      "Authorization": `Bearer ${keycloak.token}`,
      ...init?.headers
    }
  })
  if (!response.ok && throwOnError) throw new Error(`${response.status} ${response.statusText}`)
  return response
}

export async function getProjects(filter: ProjectFilter): Promise<{ items: Project[], totalCount: number }> {
  const query = new URLSearchParams({
    offset: String((filter.currentPage - 1) * filter.itemsPerPage),
    limit: String(filter.itemsPerPage),
    marketNumber: String(filter.marketNumber),
    admin: String(filter.admin),
    query: String(filter.query)
  })
  console.log(filter);
  
  if (filter.status.length > 0) query.set("status", filter.status.join(","))
  if (filter.type.length > 0) query.set("type", filter.type.join(","))
  const response = await fetchAPI(`/api/projects?${query}`)
  const body = await response.json()
  return { items: body.items.map(mapProject), totalCount: body.totalCount }
}

export async function getProject(id: number): Promise<Project> {
  const response = await fetchAPI(`/api/projects/${id}`)
  return mapProject(await response.json())
}

export async function deleteProject(id: number) {
  await fetchAPI(`/api/projects/${id}`, { method: "DELETE" })
}

export async function createProject(project: Partial<Project>): Promise<Project> {
  const response = await fetchAPI(`/api/projects`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(project)
  })
  return response.json()
}

export async function updateProject(project: Partial<Project>) {
  const response = await fetchAPI(`/api/projects/${project.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ...project, id: undefined, owner: undefined, createdAt: undefined, lastUpdated: undefined })
  })
  return response.json()
}

export async function getBom(projectId: number): Promise<Bom> {
  const response = await fetchAPI(`/api/projects/${projectId}/bom`)
  return response.json()
}

export async function updateArticles(data: string) {
  return await fetchAPI("/api/articles", {
    method: "POST",
    headers: {
      "Content-Type": "text/csv"
    },
    body: data
  })
}

export async function getArticles() {
  const res = await fetchAPI("/api/articles")
  return await res.text()
}

export async function getExcel(projectId: number) {
  const res = await fetchAPI(`/api/projects/${projectId}/excel`)
  return await res.blob()
}

export async function requestSupport(projectId: number) {
  const res = await fetchAPI(`/api/projects/market/${projectId}/support`)
  return res.status
}

export async function requestOffer(projectId: number) {
  const res = await fetchAPI(`/api/projects/market/${projectId}/offer`)
  return res.status
}

export async function getPromoCodes(market: string) {
  return await (await fetchAPI(`/api/promo`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "market": market
    }
  })).json()

}

export async function getAllPromoCodes() {
  return await (await fetchAPI(`/api/promo/all`)).json()
}