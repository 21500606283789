import * as THREE from "three"
import { Awning, logic } from "@ldvsg/shared"
import { Texture } from 'three'
const ral = require("@/data/ralColor.json");

const cameraShearMatrix = new THREE.Matrix4().set(
  1, 0, -0.5 * Math.cos(Math.PI / 6), 0,
  0, 1, -0.5 * Math.sin(Math.PI / 6), 0,
  0, 0, 1, 0,
  0, 0, 0, 1,
)

export interface VisualizationInput {
  showMeasurements: boolean
  showLighting: boolean
  width: number
  depth: number
  heightWall: number
  heightGutter: number
  postCount: number
  chevronCount: number
  awning: Awning
  postOffsets: number[]
  colorCode: number
}

export class Visualization {
  renderer: THREE.WebGLRenderer
  scene: THREE.Scene
  camera: THREE.OrthographicCamera
  frustumSize = 1
  group = new THREE.Group()
  awningTexture: Texture

  constructor(public canvas: HTMLCanvasElement) {
    const renderer = this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true
    })
    this.awningTexture = new THREE.TextureLoader()
      .load(require(`../assets/materials/fabrics/U137.png`), () => this.render())
      // .load(require(`../assets/materials/fabrics/uv.jpg`), () => this.render())
    this.awningTexture.wrapS = this.awningTexture.wrapT = THREE.RepeatWrapping
    this.awningTexture.encoding = THREE.sRGBEncoding
    this.awningTexture.repeat.set(1, 1)

    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.physicallyCorrectLights = true
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.VSMShadowMap

    this.scene = new THREE.Scene()

    const light = new THREE.DirectionalLight("#fff", 4)
    light.position.set(-4, 6, 4)
    light.castShadow = true
    light.shadow.bias = -0.0004
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.camera.near = -10;
    light.shadow.camera.far = 30;
    light.shadow.radius = 16

    this.scene.add(light)
    this.scene.add(new THREE.HemisphereLight("#bbb", "#889", 3))

    const ground = new THREE.Mesh(new THREE.PlaneGeometry(32, 32), new THREE.MeshStandardMaterial({ color: "#e0e0e0" }))
    const groundMaterial = new THREE.TextureLoader()
      .load(require(`../assets/materials/concrete.png`), () => {
        this.render()
      })
    groundMaterial.wrapS = groundMaterial.wrapT = THREE.RepeatWrapping
    groundMaterial.encoding = THREE.sRGBEncoding
    groundMaterial.repeat.set(32, 32)
    ground.material.map = groundMaterial
    ground.rotateX(-Math.PI / 2)
    this.scene.add(ground)

    const wall = new THREE.Mesh(new THREE.PlaneGeometry(32, 4), new THREE.MeshStandardMaterial({ color: "#afafaf" }))
    const wallMaterial = new THREE.TextureLoader()
      .load(require(`../assets/materials/large_red_bricks_diff_1k.jpg`), () => {
        this.render()
      })
    wallMaterial.wrapS = wallMaterial.wrapT = THREE.RepeatWrapping
    wallMaterial.encoding = THREE.sRGBEncoding
    wallMaterial.repeat.set(32*0.6, 4*0.6)
    wall.material.map = wallMaterial
    wall.position.set(0, 2, 0)
    wall.receiveShadow = true
    this.scene.add(wall)

    this.scene.add(this.group)

    this.camera = new THREE.OrthographicCamera(0, 0, 0, 0, -10, 10)
    this.frustumSize = 5

    this.updateSize = this.updateSize.bind(this)
  }

  update(input: VisualizationInput) {
    // awning.roofAwnings.fabricID
    this.awningTexture = new THREE.TextureLoader()
      .load(require(`../assets/materials/fabrics/${(input.awning.roofAwnings.fabricID || 'U137').toUpperCase()}.png`), () => this.render())
      // .load(require(`../assets/materials/fabrics/uv.jpg`), () => this.render())
    this.awningTexture.wrapS = this.awningTexture.wrapT = THREE.RepeatWrapping
    this.awningTexture.encoding = THREE.sRGBEncoding
    this.awningTexture.repeat.set(1, 1)

    this.group.traverse(object => {
      if (object instanceof THREE.Mesh) {
        if (object.material.map instanceof THREE.Texture) object.material.map.dispose()
        object.material.dispose()
        object.geometry.dispose()
      }
    })
    this.group.children = []

    this.frustumSize = Math.max(input.depth / 3 + 3, input.width / 1.6)
    this.camera.position.set(0, (input.heightWall + input.heightGutter) / 3.5, input.depth / 2)

    const skewMatrix = new THREE.Matrix4().set(
      1, 0, 0, 0,
      0, 1, -(input.heightWall - input.heightGutter) / input.depth, 0,
      0, 0, 1, 0,
      0, 0, 0, 1
    )

    const color =  input.colorCode in ral ? ral[input.colorCode!] : "#F6F6F6"
    const material = new THREE.MeshStandardMaterial({ color, metalness: 0.5 })

    for (let i = input.postCount; i--;) {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(0.12, input.heightGutter, 0.12), material)
      mesh.position.set((i / (input.postCount - 1) - 0.5) * (input.width - 0.12) + input.postOffsets[i], input.heightGutter / 2, input.depth - 0.06)
      this.group.add(mesh)
    }

    for (let i = input.chevronCount; i--;) {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(0.05, 0.15, input.depth), material)
      mesh.position.set((i / (input.chevronCount - 1) - 0.5) * (input.width - 0.05), input.heightWall - (input.heightWall - input.heightGutter) / 2, input.depth / 2)
      mesh.geometry.applyMatrix4(skewMatrix)
      this.group.add(mesh)
    }

    {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(input.width, 0.15, 0.05), material)
      mesh.position.set(0, input.heightWall, 0.025)
      this.group.add(mesh)
    }

    {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(input.width, 0.15, 0.05), material)
      mesh.position.set(0, input.heightGutter, input.depth - 0.025)
      this.group.add(mesh)
    }

    {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(input.width, 0.15, 0.05), material)
      mesh.position.set(0, input.heightWall, 0.025)
      this.group.add(mesh)
    }

    {
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(input.width, 0.15, 0.05), material)
      mesh.position.set(0, input.heightGutter, input.depth - 0.025)
      this.group.add(mesh)
    }

    for (const awning of input.awning.roofAwnings.awnings) {
      const w = input.width / (input.chevronCount - 1)
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(w, input.depth, 0.01), new THREE.MeshStandardMaterial({
        color: "#ccc"
      }))

      mesh.material.map = this.awningTexture

      mesh.geometry.translate(w / 2, 0, 0)
      mesh.geometry.applyMatrix4(skewMatrix.clone().multiply(new THREE.Matrix4().makeRotationX(-Math.PI / 2)))
      mesh.geometry.scale(awning.end! - awning.start! - 0.08 / w, 1, 1)
      mesh.geometry.computeBoundingBox()
      const size = mesh.geometry.boundingBox!.getSize(new THREE.Vector3())
      const factor = 1;
      this.awningTexture.repeat.set(size.x*factor, size.z*factor)
      
      // mesh.geometry.faceVertexUvs = mesh.geometry.faceVertexUvs.map(face => face.map(f => f.map(a => a.multiply(new THREE.Vector2(size.x / 2)))))
      // mesh.geometry.uvsNeedUpdate = true
      // je nach Markisentyp werden die Markisen auch unter die Sparren verschoben
      const typeSpecificOffset = {
        y: input.awning.variant === "under_glass" ? -0.05 : 0.1,
        z: input.awning.variant === "under_glass" ? -0.01 : 0.05
      }
      mesh.position.set(
        -input.width / 2 + awning.start! * w + 0.04,
        input.heightWall - (input.heightWall - input.heightGutter) / 2 + typeSpecificOffset.y,
        input.depth / 2 + typeSpecificOffset.z
      ) 
      this.group.add(mesh)
    }

    for (let i = 0; i < input.awning.frontAwnings.awnings.length; i++) {
      var awningStartPoint = 0
      const awning = input.awning.frontAwnings.awnings[i]
      const w = logic.offsetPanelWidth(awning.start!, input.postOffsets.map((element: number) => element * 1000), input.width * 1000, input.postCount ) / 1000
      
      const mesh = new THREE.Mesh(new THREE.BoxGeometry(w, input.heightGutter / 2, 0.01), new THREE.MeshStandardMaterial({
        color: "#ccc"
      }))

      mesh.material.map = this.awningTexture

      for (let j = 0; j < awning.start! + 1; j++) //Startpunkt der Markise berechnen anhand des Feldes und der Pfostenoffsets
        if (j > 0) awningStartPoint += logic.offsetPanelWidth(j - 1, input.postOffsets.map((element: number) => element * 1000), input.width * 1000, input.postCount) / 1000
        else awningStartPoint += input.postOffsets[j]

      mesh.geometry.translate(w / 2, 0, 0)
      mesh.geometry.scale(awning.end! - awning.start! - 0.06 / w, 1, 1)
      mesh.geometry.computeBoundingBox()
      const size = mesh.geometry.boundingBox!.getSize(new THREE.Vector3())
      mesh.geometry.faceVertexUvs = mesh.geometry.faceVertexUvs.map(face => face.map(f => f.map(a => a.multiply(new THREE.Vector2(size.x / 2)))))
      mesh.geometry.uvsNeedUpdate = true
      mesh.position.set(-input.width / 2 + awningStartPoint , input.heightGutter * 0.75 + 0.05, input.depth - 0.05)
      this.group.add(mesh)
    }

    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) object.receiveShadow = true
    })

    const mesh = new THREE.Mesh(new THREE.BoxGeometry(input.width, input.depth, 0.02), new THREE.MeshStandardMaterial({
      color: "#aab",
      opacity: 0.5,
      transparent: true
    }))
    mesh.position.set(0, input.heightWall - (input.heightWall - input.heightGutter) / 2 + 0.08, input.depth / 2)
    mesh.geometry.applyMatrix4(skewMatrix.clone().multiply(new THREE.Matrix4().makeRotationX(-Math.PI / 2)))
    this.group.add(mesh)

    this.render()
  }

  updateSize() {
    const bounds = this.canvas.getBoundingClientRect()
    const width = Math.round(bounds.width * devicePixelRatio)
    const height = Math.round(bounds.width / 2 * devicePixelRatio)
    const aspect = width / height
    this.renderer.setSize(width, height, false)
    this.camera.left = -this.frustumSize
    this.camera.right = this.frustumSize
    this.camera.top = this.frustumSize / aspect
    this.camera.bottom = -this.frustumSize / aspect
    this.camera.updateProjectionMatrix()
    this.camera.projectionMatrix.multiply(cameraShearMatrix)
    this.camera.updateMatrixWorld()
    this.render()
  }

  render() {
    this.renderer.render(this.scene, this.camera)
  }

  dispose() {
    this.renderer.dispose()
    this.awningTexture.dispose()
    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        object.material.dispose()
        object.geometry.dispose()
      }
    })
  }
}
