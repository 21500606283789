





































































































































































import { BvTableFieldArray } from "bootstrap-vue";
import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Awning, logic, Bom, SidePanels } from "@ldvsg/shared"
import { mapGetters } from "vuex"
import * as profileConfig from "@profile/config"

interface BomRow {
  article_id: number | null;
  position: string;
  depth: number;
  description: string;
  price: number | null;
  amount: number | null;
  unit: string;
  totalprice: number;
  purchasePrice: number | null;
  salesPrice: number | null;
  _rowVariant: string;
}

export default Vue.extend({
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      retNr: null as number | null,
      payment: cloneDeep(this.$store.state.project.payment),
      configuration: cloneDeep(this.$store.state.project.configuration),
      discount: 0,
      showAlert: false,
      fields: [
        { key: "position", label: "#" },
        {
          key: "amount",
          formatter: (amount?: number) => {
            if(parseFloat((amount || 0).toString()) !== parseInt((amount || 0).toString())){
              return amount?.toFixed(1)
            } else {
              return amount
            }
          },
          label: "Menge",
          class: "text-right",
        },
        {
          key: "unit",
          formatter: (unit: string) => {
            switch (unit) {
              case "Stück":
                return "Stk";
              case "lfdm":
                return "m";
              case "m2":
                return "m²";
              case "Feld":
                return "Feld";
              default:
                return "";
            }
          },
          label: "",
          class: "pl-0",
        },
        { key: "description", label: "Beschreibung" },
        {
          key: "price",
          formatter: (price: number) => {
            if (price != null)
              return price.toLocaleString("de", {
                style: "currency",
                currency: "EUR",
              });
            else return ""
          },
          label: "Einzelpreis",
          class: "text-right",
        },
        {
          key: "totalprice",
          formatter: (price: number) => {
            if (price != null)
              return price.toLocaleString("de", {
                style: "currency",
                currency: "EUR",
              });
            else return ""
          },
          label: "Gesamtpreis",
          class: "text-right",
        },
      ] as BvTableFieldArray,
      profileConfig,
      re: profileConfig.config.retrievalRegex,
      retTitle: profileConfig.retrievalNumber
    };
  },
  computed: {
    ...mapGetters([
      "fieldWidth",
      "roofLength",
      "heightGutter",
      "postOffsets"
    ]),
    totalPosts(): number { return this.$store.state.project.configuration.postCount },
    awning(): Awning {
      return cloneDeep(this.$store.state.project.awning)
    },
    sidePanels(): SidePanels {
      return cloneDeep(this.$store.state.project.sidePanels)
    },
    bom(): Bom | null {
      return this.$store.state.bom;
    },
    items(): BomRow[] {
      if (this.bom == null) return [];

      return this.bom.groups.filter(f => f.totalPrice != 0)
        .map((group, index): BomRow[] => {
          return [
            {
              article_id: null,
              position: `${index + 1}`,
              depth: 0,
              description: group.description,
              amount: null,
              unit: "",
              price: null,
              totalprice: profileConfig.modules.showSalesPrice ? group.totalSalesPrice : group.totalPrice,
              purchasePrice: null,
              salesPrice: null,
              _rowVariant: "secondary",
            },
            ...group.items.map(
              (item, i): BomRow => ({
                article_id: item.article._id,
                position: `${index + 1}.${i + 1}`,
                depth: 1,
                description: this.sizedDescription(item.article.description, i),
                amount: group.description != "Rabatte" ? item.amount : null,
                unit: group.description != "Rabatte" ? item.article.unit : "",
                price:
                  group.description != "Rabatte" ?
                    profileConfig.modules.showSalesPrice ?
                      item.article.salesPrice ?
                      item.article.salesPrice : item.article.price : item.article.price : null,
                totalprice:
                  profileConfig.modules.showSalesPrice ?
                    item.totalSalesPrice : item.totalPrice,
                purchasePrice: item.article.purchasePrice,
                salesPrice: item.article.salesPrice,
                _rowVariant: "",
              })
            ),
          ];
        })
        .flat();
    },
    discountedPrice(): number {
      const paymentDiscount = 1 - this.$store.getters.discount / 100;
      if (this.bom != null)
        return (
          this.bom.totalPrice * paymentDiscount -
          this.discount -
          this.payment.specialDiscount
        );
      return 0;
    },
    totalBomPrice(): number {
      if (this.bom != null && this.bom.totalPrice != null) {
        if (profileConfig.modules.showSalesPrice) return this.bom.totalSalesPrice
        else return this.bom.totalPrice
      }
      else return 0;
    },
    totalPurchasePrice(): number {
      return this.bom ? this.bom.totalPurchasePrice : 0
    },
    missingFields(): boolean {
      const heightDiff = this.configuration.heightDifferenceOfRoofAndHouse === "" || this.configuration.heightDifferenceOfRoofAndHouse == null
      const median = this.configuration.medianHeightOfHouse === "" || this.configuration.medianHeightOfHouse == null
      return heightDiff || (this.configuration.gableSide && median)
    }
  },
  methods: {
    sizedDescription(desc: string, idx: number): string {
      const { width, depth } = this.$store.getters.basicConfig
      if(desc.includes("Terrassendach")) {
        return `${desc} ${
          this.configuration.roofingVariant.includes('poly_klar') ?
            'Poly klar - ' : this.configuration.roofingVariant.includes('opal') ?
              'Poly Opal - ' : '- '
        }RAL${this.configuration.colorCode} ${
          this.configuration.colorHasStructure ? "Struktur - " : "Glatt - "
        }Pfosten: ${
          this.configuration.postVariant === "abgerundet" ?
            "Abgerundet - " : "Eckig - "
        }${width}mm x ${depth}mm`
      } else if(desc.includes("Beleuchtung Set")) {
        if(desc.includes("Zusatz")) return `${desc} (Insgesamt ${this.$store.state.project.lighting.additionalAmount} LED's)`
        else return `${desc} (Insgesamt ${this.$store.state.project.lighting.amount} LED's)`
      } else if(desc.includes("Unterdachmarkise") || desc.includes("Aufdachmarkise")) {
        const awningSize = logic.awningSize(
          0,
          this.awning.roofAwnings.awnings[idx]?.start?? 0,
          this.awning.roofAwnings.awnings[idx]?.end?? 0,
          this.fieldWidth,
          width,
          this.totalPosts,
          this.postOffsets,
          this.awning.variant ?? "",
          depth
        )
        const awningWidth = awningSize.width
        const awningDepth = awningSize.depth
        var res = `${desc} - ca. ${awningWidth}mm x ${awningDepth}mm`
        if(!profileConfig.modules.showFullFabricBrand) res += ` - Stoffnummer: ${this.awning.roofAwnings.fabricID?.toUpperCase()}`
        let side = ""
        switch(this.awning.roofAwnings.awnings[idx]?.engineSide) {
          case "left":
            side = "Links"
            break
          case "right":
            side = "Rechts"
            break
        }
        res += ` - Motorseite: ${side}`
        return res
      } else if(desc.includes("Senkrechtmarkise") && !desc.includes("Montage")) {
        const awningSize = logic.awningSize(
          1,
          this.awning.frontAwnings.awnings[idx + this.awning.roofAwnings.awnings.length]?.start?? 0,
          this.awning.frontAwnings.awnings[idx + this.awning.roofAwnings.awnings.length]?.end?? 0,
          this.fieldWidth,
          width,
          this.totalPosts,
          this.postOffsets,
          this.awning.variant ?? "",
          depth,
          this.heightGutter
        )
        const awningWidth = awningSize.width
        const awningDepth = awningSize.depth
        return `${desc} - ca. ${awningWidth}mm x ${awningDepth}mm`
      } else if(desc.includes("GGA Front")){
        const sidePanel = this.sidePanels!.sidePanels!.filter(sp => sp?.variant)[idx]!;
        return `GGA Vorne (${sidePanel.opening === 'left' ? 'Von Links nach Rechts': 'Von Rechts nach Links'})`
      } else if(desc.includes("GGA Seite")){
        const sidePanel = this.sidePanels!.sidePanels!.filter(sp => sp?.variant)[idx]!;
        console.log(sidePanel);
        return `GGA Seite inkl. Keil (${sidePanel.opening === 'toWall' ? 'vom Pfosten zur Hauswand': 'von der Hauswand zum Pfosten'})`
      }
      return desc
    },
    async placeOrder() {
      if (! await (this.$refs.refForm as any).validate()) return
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        status: "orderPlaced",
        retrievalNumberOrder: this.retNr,
        orderPlaced: true
      })
      await this.$store.dispatch("requestOffer")
      
      this.$bvModal.hide('retrievalNumber')
    },
    async doDiscount() {
      if (this.payment.specialDiscount === "") this.payment.specialDiscount = 0
      if (this.payment.specialDiscount < this.totalBomPrice * 0.03) {
        await this.$store.dispatch("updateProject", {
          ...this.$store.state.project,
          payment: {
            ...this.$store.state.project.payment,
            specialDiscount: this.payment.specialDiscount,
          },
        });
        this.showAlert = false;
      } else this.showAlert = true;
    },
    async downloadPDF() {
      const project = this.$store.state.project
      const blob: Blob = await this.$store.dispatch("generatePDF", project)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(blob)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async downloadExcel() {
      await this.save()
      const projectId = this.$store.state.project.id
      const blob: Blob = await this.$store.dispatch("getExcel")
      const a = document.createElement("a")
      a.href = URL.createObjectURL(blob)
      a.download = `Statik${projectId}`
      document.body.appendChild(a)
      a.click()
      a.remove()
      URL.revokeObjectURL(a.href)
    },
    async save() {
      this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        configuration: cloneDeep(this.configuration),
      });
      this.$store.commit("setUnsavedChanges", false);
    },
  },
  async mounted() {
    this.$root.$on('bv::modal::show', async (bvEvent: any, modalId: string) => {
      try {
        await this.save()
      } catch (e) {
        console.log(e)
      }
    })
  },
  created() {
    if (this.$store.getters.userRole("construction-market-admin")) {
      this.fields.push({
        key: "purchasePrice",
        formatter: (price: number) => {
          if (price != null)
            return price.toLocaleString("de", {
              style: "currency",
              currency: "EUR",
            });
          else return ""
        },
        label: "EK-Preis",
        class: "text-right",
      })
    }
    this.retNr = this.$store.getters.getRetrievalNumber("order")
  },
  async beforeRouteLeave(_to, _from, next) {
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },

});
